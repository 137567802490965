import * as React from "react";
import { useHistory, useLocation } from "react-router";
import {
  Button,
  Divider,
  PageHeader,
  Form,
  Input,
  InputNumber,
  Upload,
  Radio,
  message,
  Result,
} from "antd";

import { UPLOAD_ACTION, applyProduct } from "@/requests/business";
import { UploadOutlined } from "@ant-design/icons";

const UploadButton = ({ disabled = false }) => (
  <Button icon={<UploadOutlined />} disabled={disabled}>
    点击上传
  </Button>
);

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 6 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  },
};

const BusinessApply = () => {
  const [product, setProduct] = React.useState<ProductItem | undefined>(
    undefined
  );
  const [fetching, setFetching] = React.useState(false);
  const [success, setSuccess] = React.useState(false);
  const history = useHistory();
  const location = useLocation();
  const [form] = Form.useForm();
  const [accountType, setAccountType] = React.useState(1);
  const [hasInvoice, setHasInvoice] = React.useState(false);

  const doApply = async (values: ApplyProductReq) => {
    setFetching(true);
    const res = await applyProduct(values);
    if (res.code === 0) {
      message.success("提交成功");
      setSuccess(true);
    } else {
      message.error(res.message);
    }
    setFetching(false);
  };

  const handleOk = (values: any) => {
    if (!product) return;
    doApply({
      quota: values.quota,
      quota_deadline: values.quota_deadline,
      contract: values.contract
        ? values.contract.fileList[0].response.data
        : "",
      invoice: values.invoice ? values.invoice.fileList[0].response.data : "",
      invoice_number: values.invoice_number ? values.invoice_number : 0,
      invoice_total: values.invoice_total ? values.invoice_total : 0,
      debtor: values.debtor,
      account_type: values.account_type,
      other_account:
        values.account_type === 1
          ? ""
          : JSON.stringify({
              账户名: values["账户名称"] ? values["账户名称"] : "",
              账号: values["账号"] ? values["账号"] : "",
              开户行: values["开户行"] ? values["开户行"] : "",
            }),
      other_information: values.other_information
        ? values.other_information.fileList[0].response.data
        : "",
      product_id: product.id,
    });
  };

  const handleCancel = (values: any) => {
    history.goBack();
  };
  React.useEffect(() => {
    if (location.pathname === "/base/business/apply") {
      const state = location.state as any;
      if (state) {
        if (state.product) {
          setProduct(state.product);
        }
      }
    }
  }, [location]);
  return product ? (
    <div style={{ backgroundColor: "#ffffff", padding: 16 }}>
      {!success && (
        <>
          <PageHeader title={"业务申请"} onBack={() => history.goBack()} />
          <span style={{ paddingLeft: 36 }}>
            {`当前申请产品：${product.product_name}`}
          </span>
          <div
            style={{ width: "100%", display: "flex", justifyContent: "center" }}
          >
            <Form
              onFinish={handleOk}
              {...formItemLayout}
              style={{ width: 500 }}
              preserve={false}
              form={form}
              onValuesChange={(c_value) => {
                if (Object.keys(c_value)[0] === "account_type") {
                  setAccountType(c_value["account_type"]);
                }

                if (Object.keys(c_value)[0] === "invoice") {
                  if (c_value["invoice"].fileList.length > 0) {
                    setHasInvoice(true);
                  } else {
                    setHasInvoice(false);
                  }
                }
              }}
            >
              <Form.Item label="融资金额" required>
                <Form.Item
                  name="quota"
                  rules={[{ required: true, message: "请输入融资额度" }]}
                  noStyle
                >
                  <InputNumber
                    placeholder="请输入融资金额"
                    style={{ width: 160 }}
                  />
                </Form.Item>
                <span style={{ paddingLeft: 8 }}>元</span>
              </Form.Item>
              <Form.Item label="融资期限" required>
                <Form.Item
                  name="quota_deadline"
                  rules={[{ required: true, message: "请输入融资期限" }]}
                  noStyle
                >
                  <InputNumber
                    placeholder="请输入融资期限"
                    style={{ width: 160 }}
                    min={0}
                    max={1095}
                  />
                </Form.Item>
                <span style={{ paddingLeft: 8 }}>天</span>
              </Form.Item>
              <Form.Item
                label="应收账款债务人"
                name="debtor"
                rules={[{ required: true, message: "请输入应收账款债务人" }]}
              >
                <Input placeholder="请输入应收账款债务人" />
              </Form.Item>

              <Form.Item
                label="贸易合同"
                name="contract"
                rules={[{ required: true, message: "请上传真实贸易合同" }]}
              >
                <Upload
                  action={UPLOAD_ACTION}
                  onChange={({ file, fileList }) => {
                    if (file.status === "success") {
                      console.log(file.response);
                    }
                  }}
                  onRemove={() => {
                    form.setFieldsValue({ contract: undefined });
                  }}
                >
                  <UploadButton />
                </Upload>
              </Form.Item>
              <Form.Item label="贸易发票" name="invoice">
                <Upload
                  action={UPLOAD_ACTION}
                  onChange={({ file, fileList }) => {
                    if (file.status === "success") {
                      console.log(file.response);
                    }
                  }}
                  onRemove={() => {
                    form.setFieldsValue({ invoice: undefined });
                  }}
                >
                  <UploadButton />
                </Upload>
              </Form.Item>
              {hasInvoice && (
                <Form.Item label="发票数量" required>
                  <Form.Item
                    name="invoice_number"
                    rules={[{ required: true, message: "请输入发票数量" }]}
                    noStyle
                  >
                    <InputNumber
                      placeholder="请输入发票数量"
                      style={{ width: 160 }}
                      min={0}
                    />
                  </Form.Item>
                  <span style={{ paddingLeft: 8 }}>张</span>
                </Form.Item>
              )}
              {hasInvoice && (
                <Form.Item label="发票总金额" required>
                  <Form.Item
                    name="invoice_total"
                    rules={[{ required: true, message: "请输入发票总金额" }]}
                    noStyle
                  >
                    <InputNumber
                      placeholder="请输入发票总金额"
                      style={{ width: 160 }}
                      min={0}
                    />
                  </Form.Item>
                  <span style={{ paddingLeft: 8 }}>元</span>
                </Form.Item>
              )}
              <Form.Item label="其他贸易资料" name="other_information">
                <Upload
                  action={UPLOAD_ACTION}
                  onChange={({ file, fileList }) => {
                    if (file.status === "success") {
                      console.log(file.response);
                    }
                  }}
                  onRemove={() => {
                    form.setFieldsValue({ invoice: undefined });
                  }}
                >
                  <UploadButton />
                </Upload>
              </Form.Item>
              <Form.Item label="收款账户" name="account_type" initialValue={1}>
                <Radio.Group>
                  <Radio value={1}>同综合授信合同收款账户</Radio>
                  <Radio value={2}>其他账户</Radio>
                </Radio.Group>
              </Form.Item>
              {accountType === 2 && (
                <Form.Item label="账户名称" name={"账户名称"}>
                  <Input placeholder="请输入账户名称" />
                </Form.Item>
              )}
              {accountType === 2 && (
                <Form.Item label="账号" name={"账号"}>
                  <Input placeholder="请输入账号" />
                </Form.Item>
              )}
              {accountType === 2 && (
                <Form.Item label="开户行" name={"开户行"}>
                  <Input placeholder="请输入开户行" />
                </Form.Item>
              )}
              <Form.Item noStyle>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    width: "100%",
                    margin: "36px 0 24px 0",
                    padding: "0 100px",
                  }}
                >
                  <Button
                    type="primary"
                    htmlType={"submit"}
                    style={{ padding: "0 36px", width: 600 }}
                    loading={fetching}
                  >
                    确认提交
                  </Button>
                </div>
              </Form.Item>
            </Form>
          </div>
          <Divider>说明</Divider>
          <div style={{ margin: "0 48px" }}>
            <h4
              style={{
                color: "rgba(0,0,0,.45)",
                fontSize: 15,
                lineHeight: "22px",
              }}
            >
              关于业务申请
            </h4>
            <p style={{ lineHeight: "22px", color: "rgba(0,0,0,.45)" }}>
              1.如有贸易发票，请仔细核对发票总金额与发票数量；
            </p>
            <p style={{ lineHeight: "22px", color: "rgba(0,0,0,.45)" }}>
              2.请正确填写应收账款债务人全称；
            </p>
            <p style={{ lineHeight: "22px", color: "rgba(0,0,0,.45)" }}>
              3.收款账户默认为综合授信合同保理融资款收款账户，若需使用其他账户，请提供真实有效的账户，否则将影响您保理融资款到账。
            </p>
            <h4
              style={{
                color: "rgba(0,0,0,.45)",
                fontSize: 15,
                lineHeight: "22px",
              }}
            >
              关于文件上传
            </h4>
            <p style={{ lineHeight: "22px", color: "rgba(0,0,0,.45)" }}>
              1.贸易合同请上传pdf或图片文件；
            </p>
            <p style={{ lineHeight: "22px", color: "rgba(0,0,0,.45)" }}>
              2.贸易发票数量如数量较大，请上传压缩包，否则请上传pdf或图片文件；
            </p>
            <p style={{ lineHeight: "22px", color: "rgba(0,0,0,.45)" }}>
              2.其他贸易资料为可选项，如有，请上传pdf或图片文件文件。
            </p>
          </div>
        </>
      )}
      {success && (
        <Result
          status="success"
          title="申请成功!"
          subTitle="后台人员将在1-3个工作日内完成审核，审核成功会向您发送电子合同，请耐心等待。"
          extra={[
            <Button
              type="primary"
              key="console"
              onClick={() => {
                history.push("/base/my-process/business-process");
              }}
            >
              查看详情
            </Button>,
            <Button
              key="buy"
              onClick={() => {
                history.goBack();
              }}
            >
              返回
            </Button>,
          ]}
        />
      )}
    </div>
  ) : null;
};
export default BusinessApply;
