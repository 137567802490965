import * as React from "react";
import {
  Form,
  Input,
  Divider,
  PageHeader,
  Upload,
  Button,
  Layout,
  Modal,
  message,
  Checkbox,
  Image,
  Result,
} from "antd";
import { useHistory, useLocation } from "react-router-dom";
import { HomeOutlined, UploadOutlined } from "@ant-design/icons";
import { UPLOAD_ACTION, bindCompany } from "@/requests/company";
import { UploadFile, UploadListType } from "antd/lib/upload/interface";
import { UploadPicWidget } from "@/ui-components";
import { download } from "@/utils/file";

interface UploadValueType {
  file: UploadFile;
  fileList: UploadListType;
}
interface FormValues {
  company_name: string;
  register_address: string;
  contact_address: string;
  company_phone: string;
  industry_type: string;
  uniform_social_credit_code: string;
  business_license_pic: UploadValueType;
  legal_person: string;
  legal_person_phone: string;
  legal_person_address: string;
  legal_person_id_card_pic_1: UploadFile<any>[];
  legal_person_id_card_pic_2: UploadFile<any>[];
  legal_person_auth_pic: UploadFile[];
  agent_id_card_pic_1: UploadFile<any>[];
  agent_id_card_pic_2: UploadFile<any>[];
  agent_name: string;
  agent_id_card_num: string;
  agent_address: string;
  agent_phone: string;
  agent_email: string;
  legal_person_id_card_num: string;
  legal_person_email: string;
  agent_position: string;
  legal_person_position: string;
}

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};
const tailLayout = {
  wrapperCol: { offset: 8, span: 16 },
};

const BindCompany = () => {
  const [form] = Form.useForm();
  const history = useHistory();
  const [fetching, setFetching] = React.useState(false);
  const [isSameAsLegel, setIsSameAsLegel] = React.useState(false);
  const [personAVisible, setPersonAVisible] = React.useState(false);
  const [personBVisible, setPersonBVisible] = React.useState(false);
  const [isDone, setIsDone] = React.useState(false);
  const location = useLocation();

  React.useEffect(() => {
    if (location.state) {
      const { orgApply } = location.state as any;
      const {
        company_name,
        register_address,
        contact_address,
        company_phone,
        industry_type,
        uniform_social_credit_code,
        legal_person,
        legal_person_phone,
        legal_person_address,

        legal_person_id_card_num,
        legal_person_email,

        legal_person_position,
      } = orgApply as CompanyProfileApplyItem;
      form.setFieldsValue({
        company_name,
        register_address,
        contact_address,
        company_phone,
        industry_type,
        uniform_social_credit_code,
        legal_person,
        legal_person_phone,
        legal_person_address,

        legal_person_id_card_num,
        legal_person_email,

        legal_person_position,
      });
    }
  }, []);

  const doBind = async (input: BindCompanyReq) => {
    setFetching(true);
    const res = await bindCompany(input);
    if (res.code === 0) {
      message.success("绑定成功");
      setIsDone(true);
    } else {
      message.error(res.message);
    }
    setFetching(false);
  };
  const normFile = (e: any) => {
    console.log("Upload event:", e);
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };
  const submit = (input: BindCompanyReq) => {
    Modal.confirm({
      title: "提示",
      content:
        "确认提交当前企业并绑定吗？请确认您提交的企业信息无误，绑定后修改信息需重新提交申请！",
      okText: "确认",
      onOk: () => doBind(input),
      cancelText: "取消",
      onCancel: () => {},
    });
  };
  const onFinish = (values: FormValues) => {
    console.log("Received values of form: ", values);
    const {
      company_name,
      register_address,
      contact_address,
      company_phone,
      business_license_pic,
      // legal_person_auth_pic,
      legal_person,
      legal_person_position,
      legal_person_phone,
      legal_person_address,
      legal_person_id_card_pic_1,
      legal_person_id_card_pic_2,
      legal_person_email,
      legal_person_id_card_num,
      agent_name,
      agent_id_card_num,
      agent_phone,
      agent_address,
      agent_email,
      agent_id_card_pic_1,
      agent_id_card_pic_2,
      industry_type,
      uniform_social_credit_code,
      agent_position,
    } = values;

    const legal_person_id_card_pic: BindCompanyReq["legal_person_id_card_pic"] =
      [
        {
          file_name:
            legal_person_id_card_pic_1[legal_person_id_card_pic_1.length - 1]
              .response.data,
          type: 1,
        },
        legal_person_id_card_pic_2 && {
          file_name:
            legal_person_id_card_pic_2[legal_person_id_card_pic_2.length - 1]
              .response.data,
          type: 0,
        },
      ];

    const agent_id_card_pic: BindCompanyReq["agent_id_card_pic"] = isSameAsLegel
      ? legal_person_id_card_pic
      : [
          {
            file_name:
              agent_id_card_pic_1[agent_id_card_pic_1.length - 1].response.data,
            type: 1,
          },
          agent_id_card_pic_2 && {
            file_name:
              agent_id_card_pic_2[agent_id_card_pic_1.length - 1].response.data,
            type: 0,
          },
        ];

    submit({
      company_name,
      register_address,
      contact_address,
      company_phone,
      industry_type: industry_type ? industry_type : "无",
      uniform_social_credit_code,
      business_license_pic: business_license_pic.file.response.data,
      legal_person,
      legal_person_phone,
      legal_person_position,
      legal_person_address,
      // legal_person_auth_pic: legal_person_auth_pic[0].response.data,
      legal_person_id_card_pic,
      legal_person_email,
      legal_person_id_card_num,
      agent_name: isSameAsLegel ? legal_person : agent_name,
      agent_id_card_num: isSameAsLegel
        ? legal_person_id_card_num
        : agent_id_card_num,
      agent_phone: isSameAsLegel ? legal_person_phone : agent_phone,
      agent_address: isSameAsLegel ? legal_person_address : agent_address,
      agent_email: isSameAsLegel ? legal_person_email : agent_email,
      agent_id_card_pic,
      agent_position: isSameAsLegel ? legal_person_position : agent_position,
    });
  };
  const UploadButton = ({ disabled = false }) => (
    <Button icon={<UploadOutlined />} disabled={disabled}>
      点击上传
    </Button>
  );
  return (
    <Layout
      style={{
        backgroundColor: "#ffffff",
        overflow: "scroll",

        padding: 16,
      }}
    >
      <Layout.Header style={{ backgroundColor: "#ffffff", padding: 0 }}>
        <PageHeader
          title="绑定企业信息"
          onBack={() => history.goBack()}
          style={{ width: "100%" }}
          extra={[
            <Button
              type="primary"
              onClick={() => history.goBack()}
              icon={<HomeOutlined />}
            >
              返回
            </Button>,
          ]}
        />
      </Layout.Header>

      {isDone ? (
        <Result
          status="success"
          title="提交成功!"
          subTitle="工作人员将在1-3个工作日内完成资料审核，请耐心等待"
          extra={[
            <Button
              key="goback"
              type="primary"
              onClick={() => history.goBack()}
            >
              返回
            </Button>,
          ]}
        />
      ) : (
        <Layout.Content>
          <Form
            {...layout}
            colon
            form={form}
            onFinish={onFinish}
            style={{ width: "40vw", minWidth: 279, maxWidth: 600 }}
          >
            <Divider>公司基本信息</Divider>
            <Form.Item
              label="公司名称"
              name="company_name"
              rules={[{ required: true, message: "请输入公司名称" }]}
            >
              <Input placeholder="请输入公司名称" />
            </Form.Item>

            <Form.Item
              label="注册地址"
              name="register_address"
              rules={[{ required: true, message: "请输入注册地址" }]}
            >
              <Input.TextArea placeholder="请输入注册地址" />
            </Form.Item>
            <Form.Item
              label="联系地址"
              name="contact_address"
              rules={[{ required: true, message: "请输入联系地址" }]}
            >
              <Input.TextArea placeholder="请输入联系地址" />
            </Form.Item>
            <Form.Item
              label="企业电话"
              name="company_phone"
              rules={[{ required: true, message: "请输入企业电话" }]}
            >
              <Input placeholder="请输入企业电话" />
            </Form.Item>
            <Form.Item
              label="社会统一信用代码"
              name="uniform_social_credit_code"
              rules={[{ required: true, message: "请输入社会统一信用代码" }]}
            >
              <Input placeholder="请输入社会统一信用代码" />
            </Form.Item>
            <Form.Item
              label="营业执照"
              name="business_license_pic"
              extra="请上传原件照片，支持格式包括.jpg/.png，文件大小不超过10M"
              rules={[{ required: true, message: "请上传企业营业执照" }]}
              hasFeedback
            >
              {/* <Upload
                action={UPLOAD_ACTION}
                onChange={({ file, fileList }) => {
                  if (file.status === "success") {
                    console.log(file.response);
                  }
                }}
                onRemove={() => {
                  form.setFieldsValue({ business_license_pic: undefined });
                }}
                // 检测上传文件类型是否为图片，若不是上传失败
                beforeUpload={(file: any) => {
                  const isJpgOrPng =
                    file.type === "image/jpeg" ||
                    file.type === "image/png" ||
                    file.type === "image/jpg";
                  if (!isJpgOrPng) {
                    message.error("只支持上传 JPG/PNG 文件!");
                  }
                  const isLt10M = file.size / 1024 / 1024 < 10;
                  if (!isLt10M) {
                    message.error("文件大小不能超过10M!");
                  }
                  return isJpgOrPng && isLt10M;
                }}
              >
                <UploadButton />
              </Upload> */}
              <UploadPicWidget action={UPLOAD_ACTION} />
            </Form.Item>
            <Divider>法人信息</Divider>
            <Form.Item
              label="法人姓名"
              name="legal_person"
              rules={[{ required: true, message: "请输入法人姓名" }]}
            >
              <Input placeholder="请输入法人姓名" />
            </Form.Item>
            <Form.Item
              label="法人职位"
              name={"legal_person_position"}
              rules={[{ required: true, message: "请输入法人职位" }]}
            >
              <Input placeholder="请输入法人职位" disabled={isSameAsLegel} />
            </Form.Item>
            <Form.Item
              label="法人身份证号"
              name="legal_person_id_card_num"
              rules={[{ required: true, message: "请输入法人身份证号" }]}
            >
              <Input placeholder="请输入法人身份证号" />
            </Form.Item>
            <Form.Item label="法人身份证">
              <div style={{ marginTop: 4, color: "red" }}>
                请上传原件正反面照片或扫描件
              </div>
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  justifyContent: "space-between",
                  paddingTop: 8,
                }}
              >
                <Form.Item
                  style={{ display: "inline-block" }}
                  name="legal_person_id_card_pic_1"
                  rules={[{ required: true, message: "请上传法人身份证正面" }]}
                  extra={
                    <span>
                      身份证正面{" "}
                      <a
                        onClick={(e) => {
                          e.preventDefault();
                          setPersonAVisible(true);
                        }}
                      >
                        示例
                      </a>
                    </span>
                  }
                  valuePropName="value"
                >
                  <UploadPicWidget action={UPLOAD_ACTION} />
                </Form.Item>
                <Form.Item
                  style={{ display: "inline-block" }}
                  name="legal_person_id_card_pic_2"
                  rules={[{ required: true, message: "请上传法人身份证反面" }]}
                  extra={
                    <span>
                      身份证反面{" "}
                      <a
                        onClick={(e) => {
                          e.preventDefault();
                          setPersonBVisible(true);
                        }}
                      >
                        示例
                      </a>
                    </span>
                  }
                >
                  <UploadPicWidget action={UPLOAD_ACTION} />
                </Form.Item>
              </div>
            </Form.Item>
            <Form.Item
              label="法人电话号码"
              name="legal_person_phone"
              rules={[
                { required: true, message: "请输入法人电话号码" },
                {
                  len: 11,
                  message: "请输入正确格式的手机号码",
                },
              ]}
            >
              <Input placeholder="请输入法人电话号码" />
            </Form.Item>
            <Form.Item
              label="法人电子邮箱"
              name="legal_person_email"
              rules={[
                { required: true, message: "请输入法人电子邮箱" },
                {
                  type: "email",
                  message: "请输入正确格式的邮箱",
                },
              ]}
            >
              <Input placeholder="请输入法人电子邮箱" />
            </Form.Item>
            <Form.Item
              label="法人联系地址"
              name="legal_person_address"
              rules={[{ required: true, message: "请输入法人联系地址" }]}
            >
              <Input.TextArea placeholder="请输入法人联系地址" />
            </Form.Item>

            <Divider>经办人信息</Divider>
            <Form.Item label="经办人姓名" required>
              <Form.Item
                noStyle
                name={isSameAsLegel ? "legal_person" : "agent_name"}
                rules={[{ required: true, message: "请输入经办人姓名" }]}
              >
                <Input
                  placeholder="请输入经办人姓名"
                  disabled={isSameAsLegel}
                />
              </Form.Item>
              <Checkbox
                onChange={(e) => {
                  console.log(e.target.checked);
                  setIsSameAsLegel(e.target.checked);
                }}
                style={{ marginTop: 8 }}
                value={isSameAsLegel}
                checked={isSameAsLegel}
              >
                同法人
              </Checkbox>
            </Form.Item>
            <Form.Item
              label="经办人职位"
              name={isSameAsLegel ? "legal_person_position" : "agent_position"}
              rules={[{ required: true, message: "请输入经办人职位" }]}
            >
              <Input placeholder="请输入经办人职位" disabled={isSameAsLegel} />
            </Form.Item>
            <Form.Item
              label="经办人身份证号"
              name={
                isSameAsLegel ? "legal_person_id_card_num" : "agent_id_card_num"
              }
              rules={[{ required: true, message: "请输入经办人身份证号" }]}
            >
              <Input
                placeholder="请输入经办人身份证号"
                disabled={isSameAsLegel}
              />
            </Form.Item>
            {!isSameAsLegel && (
              <Form.Item label="经办人身份证">
                <div style={{ marginTop: 4, color: "red" }}>
                  请上传原件正反面照片或扫描件
                </div>
                <div
                  style={{
                    display: "flex",
                    width: "100%",
                    justifyContent: "space-between",
                    paddingTop: 8,
                  }}
                >
                  <Form.Item
                    style={{ display: "inline-block" }}
                    name={
                      isSameAsLegel
                        ? "legal_person_id_card_pic_1"
                        : "agent_id_card_pic_1"
                    }
                    rules={[
                      { required: true, message: "请上传经办人身份证正面" },
                    ]}
                    extra={
                      <span>
                        身份证正面{" "}
                        <a
                          onClick={(e) => {
                            e.preventDefault();
                            setPersonAVisible(true);
                          }}
                        >
                          示例
                        </a>
                      </span>
                    }
                    valuePropName="value"
                  >
                    <UploadPicWidget action={UPLOAD_ACTION} />
                  </Form.Item>
                  <Form.Item
                    style={{ display: "inline-block" }}
                    name={
                      isSameAsLegel
                        ? "legal_person_id_card_pic_2"
                        : "agent_id_card_pic_2"
                    }
                    rules={[
                      { required: true, message: "请上传经办人身份证反面" },
                    ]}
                    extra={
                      <span>
                        身份证反面{" "}
                        <a
                          onClick={(e) => {
                            e.preventDefault();
                            setPersonBVisible(true);
                          }}
                        >
                          示例
                        </a>
                      </span>
                    }
                  >
                    <UploadPicWidget
                      action={UPLOAD_ACTION}
                      data={{ type: 4 }}
                    />
                  </Form.Item>
                </div>
              </Form.Item>
            )}

            <Form.Item
              label="经办人电话号码"
              name={isSameAsLegel ? "legal_person_phone" : "agent_phone"}
              rules={[
                { required: true, message: "请输入经办人电话号码" },
                {
                  len: 11,
                  message: "请输入正确格式的手机号码",
                },
              ]}
            >
              <Input placeholder="请输入经办人电话" disabled={isSameAsLegel} />
            </Form.Item>
            <Form.Item
              label="经办人电子邮箱"
              name={isSameAsLegel ? "legal_person_email" : "agent_email"}
              rules={[
                { required: true, message: "请输入经办人邮箱" },
                {
                  type: "email",
                  message: "请输入正确格式的邮箱地址",
                },
              ]}
            >
              <Input placeholder="请输入经办人邮箱" disabled={isSameAsLegel} />
            </Form.Item>
            <Form.Item
              label="经办人联系地址"
              name={isSameAsLegel ? "legal_person_address" : "agent_address"}
              rules={[{ required: true, message: "请输入经办人联系地址" }]}
            >
              <Input.TextArea
                placeholder="请输入经办人联系地址"
                disabled={isSameAsLegel}
              />
            </Form.Item>

            {/* <Form.Item
              label="法人授权书"
              extra="请上传原件照片、扫描件、或复印件加盖公章，支持格式包括.jpg/.png/.pdf，文件大小不超过10M"
            >
              <div style={{ display: "flex" }}>
                <Form.Item
                  name="legal_person_auth_pic"
                  noStyle
                  valuePropName="fileList"
                  getValueFromEvent={normFile}
                  rules={[
                    { required: true, message: "请上传法人授权书！" },
                    {
                      validator: (rule, value) => {
                        if (!value) return Promise.reject("");
                        const [file] = value;
                        if (!file) return Promise.reject("");
                        if (!file.response) {
                          return Promise.reject("上传失败，请重新上传！");
                        } else {
                          if (file.response.code === 0) {
                            return Promise.resolve();
                          } else {
                            return Promise.reject("上传失败，请重新上传！");
                          }
                        }
                      },
                    },
                  ]}
                  hasFeedback
                >
                  <Upload
                    style={{ width: 160 }}
                    action={UPLOAD_ACTION}
                    onChange={({ file, fileList }) => {
                      if (file.status === "success") {
                        console.log(file.response);
                      }
                    }}
                    beforeUpload={(file: any) => {
                      const isJpgOrPng =
                        file.type === "image/jpeg" ||
                        file.type === "image/png" ||
                        file.type === "application/pdf";
                      if (!isJpgOrPng) {
                        message.error("只支持上传 JPG/PNG/PDF 文件!");
                      }
                      const isLt10M = file.size / 1024 / 1024 < 10;
                      if (!isLt10M) {
                        message.error("文件大小不能超过10M!");
                      }
                      return isJpgOrPng && isLt10M;
                    }}
                    onRemove={() => {
                      form.setFieldsValue({
                        legal_person_auth_pic: undefined,
                      });
                    }}
                  >
                    <UploadButton />
                  </Upload>
                </Form.Item>
                <a
                  href=""
                  style={{ margin: "4px 8px" }}
                  onClick={(e) => {
                    e.preventDefault();
                    download("/api/file/protocol/download/2", "授权委托书");
                  }}
                >
                  下载模板
                </a>
              </div>
            </Form.Item> */}

            <Form.Item {...tailLayout}>
              <Button type="primary" htmlType="submit" loading={fetching}>
                {fetching ? "正在提交" : "提交"}
              </Button>
            </Form.Item>
          </Form>
          <Modal
            title=""
            footer={null}
            visible={personAVisible}
            onCancel={() => {
              setPersonAVisible(false);
            }}
          >
            <Image
              src={
                "https://lq-fe-img.oss-cn-hangzhou.aliyuncs.com/lq-credit/sample/person-A.jpg"
              }
            />
          </Modal>
          <Modal
            title=""
            footer={null}
            visible={personBVisible}
            onCancel={() => {
              setPersonBVisible(false);
            }}
          >
            <Image
              src={
                "https://lq-fe-img.oss-cn-hangzhou.aliyuncs.com/lq-credit/sample/person-B.jpg"
              }
            />
          </Modal>
        </Layout.Content>
      )}
    </Layout>
  );
};
export default BindCompany;
